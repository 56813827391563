"use strict";

var App = React.createClass({
	displayName: "App",

	getInitialState: function getInitialState() {
		var mod = 3;
		return {
			items: Array.apply(null, Array(mod * 2)).map(function () {}),
			mod: mod
		};
	},
	setItems: function setItems(mod) {
		var arr = Array.apply(null, Array(mod)).map(function () {});
		this.setState({ items: arr });
	},
	updateMod: function updateMod(e) {
		var mod = parseInt(e.target.value),
		    arr = Array.apply(null, Array(mod * 2)).map(function () {});
		this.setState({
			mod: mod,
			items: arr
		});
	},
	addItem: function addItem() {
		var arr = this.state.items;
		arr.push("");
		this.setState({ items: arr });
	},
	removeItem: function removeItem() {
		if (this.state.items.length > 1) {
			var arr = this.state.items;
			arr.pop();
			this.setState({ items: arr });
		} else {
			return;
		}
	},
	getWidth: function getWidth(i) {
		return Math.round(100000 / i) / 1000 + "%";
	},
	makeFirstChildMessage: function makeFirstChildMessage(mod) {
		var styles = "/* first child message */",
		    i = 0;
		for (; i < mod; i++) {
			styles += "\nli:nth-last-child(" + mod + "n+" + i + "):first-child:before {\n\tcontent: \"Mod " + mod + " - remainder " + i + "\" \n} ";
		}
		return styles;
	},
	makeCommonCSS: function makeCommonCSS(mod) {
		return "/*\n * mod " + mod + "\n * first two, common to all queries\n */\nli:first-child {\n\twidth: 100%;\n}\nli:nth-child(2):last-child {\n\tmargin-left: 25%;\n\twidth: 50%;\n}";
	},
	makeSecondRowCSS: function makeSecondRowCSS(mod) {
		var styles = "/* styles for less than " + mod + " items */",
		    i = 2;
		for (; i < mod; i++) {
			styles += "\nli:nth-last-child(" + (i + 1) + "):first-child ~ li { width: " + this.getWidth(i) + " }";
		}
		return styles;
	},
	makeModCSS: function makeModCSS(mod) {
		var query = "/* mod query " + mod + " */\nli {\n\twidth: " + this.getWidth(mod) + ";\n}\nli:nth-last-child(" + mod + "n+0):first-child ~ li:nth-child(n+2):nth-child(-n+" + mod + ") {\n\twidth: " + this.getWidth(mod - 1) + ";\n}\nli:nth-last-child(" + mod + "n+2):first-child ~ li:nth-child(n+2):nth-child(-n+" + (mod + 2) + ") {\n\twidth: " + this.getWidth(mod - 1) + ";\n}\nli:nth-last-child(" + mod + "n+2):first-child ~ li:nth-child(n+2):nth-child(-n+3) {\n\twidth: " + this.getWidth(2) + ";\n}";
		if (mod > 3) {
			for (var i = 3; i < mod; i++) {
				query += "\nli:nth-last-child(" + mod + "n+" + i + "):first-child ~ li:nth-child(n+2):nth-child(-n+" + i + ") {\n\twidth: " + this.getWidth(i - 1) + ";\n}";
			}
		}
		return query;
	},
	componentDidUpdate: function componentDidUpdate() {
		hljs.initHighlighting.called = false;
		hljs.initHighlighting();
	},
	render: function render() {
		var itemObjects = this.state.items.map(function (item) {
			return React.createElement("li", null);
		}),
		    styles = (function (m, _this) {
			var mod = parseInt(m),
			    cssCommon = _this.makeCommonCSS(mod),
			    cssRow = _this.makeSecondRowCSS(mod),
			    cssMod = _this.makeModCSS(mod),
			    firstChildMessage = _this.makeFirstChildMessage(mod);

			return cssCommon + "\n" + cssRow + "\n" + cssMod + "\n" + firstChildMessage + "\n";
		})(this.state.mod, this);

		return React.createElement(
			"div",
			null,
			React.createElement(
				"section",
				null,
				React.createElement(
					"div",
					{ className: "hljs border padding" },
					React.createElement(
						"h2",
						null,
						"Select the mod base for your list"
					),
					React.createElement(
						"label",
						{ htmlFor: "element" },
						"Mod ",
						this.state.mod
					),
					React.createElement("input", { "data-tag": this.state.mod,
						type: "range",
						step: "1",
						min: "3",
						max: "10",
						onChange: this.updateMod,
						value: this.state.mod,
						autoFocus: true }),
					React.createElement("hr", null),
					React.createElement(
						"h2",
						null,
						"Add remove list items"
					),
					React.createElement(
						"div",
						null,
						React.createElement(
							"button",
							{ onClick: this.addItem, className: "btn btn-default" },
							React.createElement("span", { className: "fa fa-plus-circle " }),
							" Add Item"
						),
						React.createElement(
							"button",
							{ onClick: this.removeItem, className: "btn btn-default" },
							React.createElement("span", { className: "fa fa-minus-circle" }),
							" Remove Item"
						)
					),
					React.createElement("div", { className: "explanation", dangerouslySetInnerHTML: { __html: this.props.explanation } })
				)
			),
			React.createElement(
				"section",
				null,
				React.createElement(
					"div",
					{ "class": "preview" },
					React.createElement("style", { dangerouslySetInnerHTML: { __html: styles } }),
					React.createElement(
						"ul",
						null,
						itemObjects
					)
				)
			),
			React.createElement(
				"section",
				null,
				React.createElement(
					"pre",
					{ "class": "styles border" },
					React.createElement(
						"code",
						{ "class": "css" },
						styles
					)
				)
			)
		);
	}
});

$(document).ready(function () {
	var explanation = document.querySelector('.explanation').innerHTML;
	React.render(React.createElement(App, { explanation: explanation }), document.querySelector('article'));
	hljs.initHighlightingOnLoad();
});